.midfoot {
    text-align: left;
}

.logo {
    background-image: url(../assets/cdw-2023-Red.png);
    width: 6rem;
    height:3.25rem;
	margin: 1px auto;
    background-repeat:no-repeat;
    background-size:cover;
}
